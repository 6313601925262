<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div>
      <div v-for="message in messages" :key="message.ip">
        <div :class="localIp != message.ip ? 'sender' : 'receiver'">
          <div>
            <img src="@/assets/img_avatar.png" />
          </div>
          <div>
            <div
              :class="
                localIp != message.ip ? 'left_triangle' : 'right_triangle'
              "
            ></div>
            <span> {{ message.data }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="message">
      <input type="text" v-model="test" @keypress.enter="pushData(test)" />
      <button type="submit" @click="pushData(test)">Send</button>
    </div>
  </div>
</template>

<script>
import Pusher from "pusher-js";
import axios from "axios";

function isEmpty(obj) {
  if (typeof obj == "undefined" || obj == null || obj == "") {
    return true;
  } else {
    return false;
  }
}

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      messages: [],
      test: "",
      localIp: "",
    };
  },
  mounted: async function () {
    let that = this;
    Pusher.logToConsole = false;

    var pusher = new Pusher("e804c8a0cfdf9b85494d", {
      cluster: "ap3",
    });

    var channel = pusher.subscribe("channel-test");
    channel.bind("test-event", function (data) {
      that.messages.push(data);
    });
    await this.getIp();
  },
  methods: {
    async pushData(data) {
      this.test = "";
      if (!isEmpty(data)) {
        const ip = localStorage.getItem("ip");
        const res = await axios.post("/api/channels-event", { ip, data });
        console.log(res);
      } else {
        alert("请输入消息");
      }
    },
    async getIp() {
      const resIp = await axios.get("/api/ip");
      this.localIp = resIp.data.ip;
      window.localStorage.setItem("ip", this.localIp);
    },
  },
};
</script>

<style scoped>
/* bubble style */
.sender {
  clear: both;
}
.sender div:nth-of-type(1) {
  float: left;
}
.sender div:nth-of-type(2) {
  background-color: aquamarine;
  float: left;
  margin: 0 20px 10px 15px;
  padding: 10px 10px 10px 0px;
  border-radius: 7px;
}

.receiver div:first-child img,
.sender div:first-child img {
  width: 50px;
  height: 50px;
}

.receiver {
  clear: both;
}
.receiver div:nth-child(1) {
  float: right;
}
.receiver div:nth-of-type(2) {
  float: right;
  background-color: gold;
  margin: 0 10px 10px 20px;
  padding: 10px 0px 10px 10px;
  border-radius: 7px;
}

.left_triangle {
  height: 0px;
  width: 0px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent aquamarine transparent transparent;
  position: relative;
  left: -16px;
  top: 3px;
}

.right_triangle {
  height: 0px;
  width: 0px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent gold;
  position: relative;
  right: -16px;
  top: 3px;
}

.message {
  padding-top: 8%;
}
input[type="text"] {
  width: 80%;
  padding: 12px 20px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
}

button {
  width: 20%;
  background-color: #e7e7e7;
  color: black;
  border: none;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
</style>
